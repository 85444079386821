<template>
  <div class="overflow-scroll">
    <div style="min-width: 1180px">
      <a-card
        :bordered="false"
        :tabList="tabListNoTitle"
        :activeTabKey="noTitleKey"
        @tabChange="key => onTabChange(key, 'noTitleKey')"
      >
        <div
          v-if="noTitleKey === 'baseDetail'"
          :bordered="false"
          class="stallsEditLabelWidth"
        >
          <DescriptionList title="档口信息" size="large">
            <a-card
              :bordered="false"
              style="margin-left: -16px; margin-bottom: -24px"
            >
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="0" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店编号">
                        <a-input
                          style="width: 240px"
                          disabled
                          v-decorator="[
                            'facilityCode',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objBasic.facilityCode
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="16" :sm="24">
                      <a-form-item label="门店名称">
                        <a-input
                          style="width: 607px"
                          disabled
                          v-decorator="[
                            'facilityName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objBasic.facilityName
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口编号">
                        <a-input
                          style="width: 240px"
                          disabled
                          v-decorator="[
                            'code',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objBasic.code
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <!--                    <a-col :md="16" :sm="24">-->
                    <!--                      <a-form-item label="档口名称">-->
                    <!--                        <a-input-->
                    <!--                          style="width: 607px"-->
                    <!--                          v-decorator="[-->
                    <!--                            'name',-->
                    <!--                            {-->
                    <!--                              rules: [-->
                    <!--                                {-->
                    <!--                                  required: true,-->
                    <!--                                  message: '请输入！'-->
                    <!--                                }-->
                    <!--                              ],-->
                    <!--                              initialValue: objBasic.name-->
                    <!--                            }-->
                    <!--                          ]"-->
                    <!--                          placeholder="请输入"-->
                    <!--                        />-->
                    <!--                      </a-form-item>-->
                    <!--                    </a-col>-->
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口类型">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'type',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(objBasic.type)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in stallsType"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="上下架">
                        <a-select
                          :disabled="!hasKitchenStatusEdit"
                          style="width: 240px"
                          v-decorator="[
                            'bdStatus',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(objBasic.bdStatus)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in bdStatus"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <!--                    <a-col :md="8" :sm="24">-->
                    <!--                      <a-form-item label="所属厨房">-->
                    <!--                        <a-select-->
                    <!--                          style="width: 240px"-->
                    <!--                          v-decorator="[-->
                    <!--                            'dietKitchenId',-->
                    <!--                            {-->
                    <!--                              rules: [-->
                    <!--                                {-->
                    <!--                                  required: true,-->
                    <!--                                  message: '请选择!'-->
                    <!--                                }-->
                    <!--                              ],-->
                    <!--                              initialValue: this.stallsEdit.brandText-->
                    <!--                                ? this.changeBrandStatus(-->
                    <!--                                    this.stallsEdit.brandText-->
                    <!--                                  )-->
                    <!--                                : this.changeBrandStatus(-->
                    <!--                                    this.stallsEdit.brandName-->
                    <!--                                  )-->
                    <!--                            }-->
                    <!--                          ]"-->
                    <!--                          placeholder="请选择"-->
                    <!--                        >-->
                    <!--                          <a-select-option-->
                    <!--                            v-for="item in brandNameList"-->
                    <!--                            :key="item.id"-->
                    <!--                          >-->
                    <!--                            {{ item.name }}-->
                    <!--                          </a-select-option>-->
                    <!--                        </a-select>-->
                    <!--                      </a-form-item>-->
                    <!--                    </a-col>-->
                    <!--                    <a-col :md="8" :sm="24">-->
                    <!--                      <a-form-item label="城市">-->
                    <!--                        <a-select-->
                    <!--                          style="width: 240px"-->
                    <!--                          v-decorator="[-->
                    <!--                            'cityId',-->
                    <!--                            {-->
                    <!--                              rules: [-->
                    <!--                                {-->
                    <!--                                  required: true,-->
                    <!--                                  message: '请输入！'-->
                    <!--                                }-->
                    <!--                              ],-->
                    <!--                              initialValue: this.showMessage(objBasic.cityName)-->
                    <!--                            }-->
                    <!--                          ]"-->
                    <!--                          placeholder="请选择"-->
                    <!--                        >-->
                    <!--                          <a-select-option-->
                    <!--                            v-for="(val, index) in 3"-->
                    <!--                            :key="index"-->
                    <!--                          >-->
                    <!--                            {{ index }}-->
                    <!--                          </a-select-option>-->
                    <!--                        </a-select>-->
                    <!--                      </a-form-item>-->
                    <!--                    </a-col>-->
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口建筑等级">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'buildingDTO.engineeringLevel',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ],
                              initialValue: this.showMessage(
                                objBasic.buildingVO &&
                                  objBasic.buildingVO.engineeringLevel
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in buildLevel"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="招商状态">
                        <a-select
                          :disabled="!hasKitchenStatusEdit"
                          style="width: 240px"
                          v-decorator="[
                            'salesStatus',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(
                                objBasic.salesStatus
                              )
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in salesStatusList"
                            :key="item.id"
                          >
                            {{ item.value }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <!--                    <a-col :md="16" :sm="24">-->
                    <!--                      <a-form-item label="店铺名称">-->
                    <!--                        <a-input-->
                    <!--                          style="width: 607px"-->
                    <!--                          v-decorator="[-->
                    <!--                            'tenantName',-->
                    <!--                            {-->
                    <!--                              rules: [-->
                    <!--                                {-->
                    <!--                                  required: true,-->
                    <!--                                  message: '请输入！'-->
                    <!--                                }-->
                    <!--                              ],-->
                    <!--                              initialValue: objBasic.facilityName-->
                    <!--                            }-->
                    <!--                          ]"-->
                    <!--                          placeholder="请输入"-->
                    <!--                        />-->
                    <!--                      </a-form-item>-->
                    <!--                    </a-col>-->
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList title="建筑信息" size="large">
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="0" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口使用面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.usableArea',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO &&
                              objBasic.buildingVO.usableArea
                          }
                        ]"
                      />
                    </a-form-item>
                    <a-form-item label="档口建筑面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.totalArea',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO &&
                              objBasic.buildingVO.totalArea
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口吧台面积">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.barArea',
                          {
                            rules: [
                              {
                                pattern: decimalTwoDigit,
                                required: true,
                                message: '不支持的格式'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO && objBasic.buildingVO.barArea
                          }
                        ]"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口电量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.actualElectricity',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO &&
                              objBasic.buildingVO.actualElectricity
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="档口排烟量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.actualExhaust',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                required: false,
                                message: '请输入！'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO &&
                              objBasic.buildingVO.actualExhaust
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="16" :sm="24">
                    <a-form-item label="档口燃气量">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'buildingDTO.gas',
                          {
                            rules: [
                              {
                                pattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
                                message: '请输入！'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO && objBasic.buildingVO.gas
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24">
                    <a-form-item label="空调配置">
                      <a-input
                        style="width: 607px"
                        v-decorator="[
                          'buildingDTO.airConditioner',
                          {
                            rules: [
                              {
                                message: '请输入！'
                              }
                            ],
                            initialValue:
                              objBasic.buildingVO &&
                              objBasic.buildingVO.airConditioner
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <!-- <a-col :md="24" :sm="24">
                    <a-form-item label="档口平面图：">
                      <template>
                        <div
                          class="clearfix"
                          style="width: 70%;display: inline-block;margin-bottom: 12px"
                        >
                          <a-upload
                            listType="picture-card"
                            withCredentials
                            name="multipartFile"
                            :action="this.uploadUrl()"
                            :fileList="fileList"
                            accept=".png,.jpg"
                            @preview="handlePreview"
                            @change="handleChange"
                          >
                            <div v-if="fileList.length < 1">
                              <a-icon type="plus" />
                              <div class="ant-upload-text">上传</div>
                            </div>
                          </a-upload>
                          <a-modal
                            :visible="previewVisible"
                            :footer="null"
                            @cancel="handleCancel"
                          >
                            <img
                              alt="example"
                              style="width: 100%"
                              :src="previewImage"
                            />
                          </a-modal>
                        </div>
                      </template>
                    </a-form-item>
                  </a-col> -->
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
          <Divider />
          <DescriptionList title="定价信息" size="large">
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 4">
                    <a-col v-if="hasMinPriceRead || hasMinPriceEdit" :md="8" :sm="24">
                      <a-form-item label="基础服务费底价">
                        <a-input
                          :disabled="!hasMinPriceEdit"
                          style="width: 240px"
                          v-decorator="[
                            'minRent',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.minRent
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="hasStandardPriceRead || hasStandardPriceEdit" :md="8" :sm="16">
                      <a-form-item label="基础服务费标价">
                        <a-input
                          :disabled="!hasStandardPriceEdit"
                          style="width: 240px"
                          v-decorator="[
                            'retailRent',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.retailRent
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="hasStandardPriceRead || hasStandardPriceEdit" :md="8" :sm="16">
                      <a-form-item label="基础服务费最低折扣价:" :labelCol="{ style: 'width: 150px' }">
                        <a-input
                            disabled
                            style="width: 240px"
                            v-decorator="[
                            'rentMinPrice',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.rentMinPrice
                            }
                          ]"
                            placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="hasMinPriceRead || hasMinPriceEdit" :md="8" :sm="24">
                      <a-form-item label="入会费底价">
                        <a-input
                          :disabled="!hasMinPriceEdit"
                          style="width: 240px"
                          v-decorator="[
                            'entryFeeMin',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.entryFeeMin
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="hasStandardPriceRead || hasStandardPriceEdit" :md="8" :sm="24">
                      <a-form-item label="入会费标价">
                        <a-input
                          :disabled="!hasStandardPriceEdit"
                          style="width: 240px"
                          v-decorator="[
                            'entryFeeRetailPrice',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.entryFeeRetailPrice
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col v-if="hasStandardPriceRead || hasStandardPriceEdit" :md="8" :sm="24">
                      <a-form-item label="入会费最低折扣价" :labelCol="{ style: 'width: 150px' }">
                        <a-input
                            disabled
                            style="width: 240px"
                            v-decorator="[
                            'entryFeeRetail',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.entryFeeRetail
                            }
                          ]"
                            placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="16" :sm="24">
                      <a-form-item label="管理费定价">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'managerRent',
                            {
                              rules: [
                                {
                                  pattern: decimalTwoDigit,
                                  required: false,
                                  message: '不支持的格式'
                                }
                              ],
                              initialValue: objBasic.splitCostOriginal
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </div>
        <a-card
          v-if="noTitleKey === 'merchantDetail'"
          :bordered="false"
          style="margin-left: -16px"
        >
          <DescriptionList
            title="基本信息"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="30" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="店铺名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'name',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objtenant.name
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="门店名称">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'note',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="(item, index) in 2"
                            :key="index"
                          >
                            {{ index }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="经营品类">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'note',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="(item, index) in 2"
                            :key="index"
                          >
                            {{ index }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="账户名称">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'note',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="(item, index) in 2"
                            :key="index"
                          >
                            {{ index }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="银行账号">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'bankAccount',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objtenant.bankAccount
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="开户银行">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'bankName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objtenant.bankName
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="品牌名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'note',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="客户等级">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'level',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: objtenant.level
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in levelList"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
          <Divider style="margin-bottom: 32px;" />
          <DescriptionList
            title="联系方式"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <a-form layout="inline" :form="form">
                <a-row :gutter="30" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="指定联系人">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'pocName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.pocName
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="指定联系人微信">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'pocWechat',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.pocWechat
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="指定联系人电话">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'pocPhone',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.pocPhone
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="指定联系人邮箱">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'pocEmail',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.pocEmail
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="对账联系人">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'recName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.recName
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="对账联系人微信">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'recWechat',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.recWechat
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="对账联系人电话">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'recPhone',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.recPhone
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="对账联系人邮箱">
                      <a-input
                        style="width: 240px"
                        v-decorator="[
                          'recEmail',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: objtenant.recEmail
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </a-card>
          </DescriptionList>
          <Divider style="margin-bottom: 32px;" />
          <DescriptionList
            title="状态节点"
            size="large"
            style="margin-bottom: 32px;"
          >
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="30" style="line-height: 4">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="合同开始日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.contractStartDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'contractStartDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="饿了么上线日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.elmOnlineDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'elmOnlineDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="美团上线日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.mtOnlineDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'mtOnlineDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="合同结束日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.contractEndDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'contractEndDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="预计离场日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.expectMoveoutDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'expectMoveoutDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="退租申请日期">
                        <a-date-picker
                          style="width: 240px"
                          :defaultValue="
                            moment(objkitstatus.rentOutApplyDate, 'YYYY-MM-DD')
                          "
                          v-decorator="[
                            'rentOutApplyDate',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ]
                            }
                          ]"
                          placeholder="请选择"
                        />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </a-card>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button type="primary" style="margin-left: 8px" @click="conserve">
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { modal } from "ant-design-vue";
import { mapGetters } from "vuex";
import DescriptionList from "@/components/DescriptionList";
import { Divider } from "ant-design-vue";
  
import moment from "moment";
import Bus from "@/bus";
import { stallsType } from "@/objects/businessObjects";
import { hasPermissions,hasPermissionsFun } from "@/utils/auth";
import { decimalTwoDigit } from "@/utils/regex"
import { FacilityActions } from "../../../utils/actions";

export default {
  name: "stallsListEdit",
  props: ["facilityName", "facilityCode"],
  components: { DescriptionList, Divider },
  data() {
    return {
      stallsEditId: this.$route.query.stallsEditId,
      formLayout: "inline",
      visible: false,
      staffEdit: false,
      previewVisible: false,
      previewImage: "",
      objBasic: "",
      objtenant: "",
      objkitstatus: "",
      fileList: [
        {
          uid: "-1",
          name: "xxx.png",
          status: "done",
          url:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        }
      ],
      tabListNoTitle: [
        {
          key: "baseDetail",
          tab: "基本信息"
        }
        // {
        //   key: "merchantDetail",
        //   tab: "在租客户信息"
        // }
      ],
      noTitleKey: "baseDetail",
      form: this.$form.createForm(this),
      // salesStatus
      salesStatusList: [
        { id: 1, value: "招商中" },
        { id: 2, value: "预定中" },
        { id: 3, value: "转租中" },
        { id: 4, value: "已预定" },
        { id: 5, value: "已签约" },
        { id: 6, value: "停止招商" }
      ],
      //档口建筑等级
      buildLevel: [
        {
          id: "L0",
          name: "L0"
        },
        {
          id: "L1",
          name: "L1"
        },
        {
          id: "L2",
          name: "L2"
        },
        {
          id: "L3",
          name: "L3"
        },
        {
          id: "L4",
          name: "L4"
        }
      ],
      //招商状态
      bdStatus: [
        {
          id: 1,
          name: "可招商"
        },
        {
          id: 2,
          name: "不可招商"
        }
      ],
      //客户状态
      tenantStatus: [
        {
          id: 1,
          name: "预定中"
        },
        {
          id: 2,
          name: "已预订"
        },
        {
          id: 3,
          name: "签约中"
        },
        {
          id: 4,
          name: "已签约"
        },
        {
          id: 5,
          name: "改造中"
        },
        {
          id: 6,
          name: "待入驻"
        },
        {
          id: 7,
          name: "已入驻"
        },
        {
          id: 8,
          name: "申请退租中"
        },
        {
          id: 9,
          name: "退租中"
        },
        {
          id: 10,
          name: "已退租"
        },
        {
          id: 11,
          name: "已离场"
        }
      ],
      //客户等级
      levelList: [
        {
          id: 1,
          name: "中小客户"
        },
        {
          id: 2,
          name: "战略客户"
        }
      ],
      //所属厨房
      brandNameList: [
        { id: 1, name: "吉刻联盟" },
        { id: 2, name: "闪电厨房" },
        { id: 3, name: "食秀坊" },
        { id: 4, name: "橘猫" },
        { id: 5, name: "海星微厨" },
        { id: 6, name: "美食门" }
      ],
      //档口类型
      stallsType: stallsType,
      decimalTwoDigit,
    };
  },
  created() {
    axios({
      url:   this.$baseUrl + "/kitchen/query/basic",
      method: "GET",
      withCredentials: true,
      params: {
        kitchenId: this.stallsEditId
      }
    }).then(res => {
      if (res.data.obj) {
        this.objBasic = res.data.obj;
      }
    });
    Bus.$on("getTarget", target => {
      this.$router.go(-1);
    });
    // axios({
    //   url:   this.$baseUrl + "/tenant/kitchen/query",
    //   method: "GET",
    //   withCredentials: true,
    //   params: {
    //     kitchenId: this.stallsEdit.id
    //   }
    // }).then(res => {
    //   this.objtenant = res.data.obj;
    // });
    // axios({
    //   url:   this.$baseUrl + "/kitstatus/query",
    //   method: "GET",
    //   withCredentials: true,
    //   params: {
    //     kitchenId: this.stallsEdit.id
    //   }
    // }).then(res => {
    //   this.objkitstatus = res.data.obj;
    // });
  },
  activated() {
    axios({
      url:   this.$baseUrl + "/kitchen/query/basic",
      method: "GET",
      withCredentials: true,
      params: {
        kitchenId: this.$route.query.stallsEditId
      }
    }).then(res => {
      if (res.data.obj) {
        this.objBasic = res.data.obj;
      }
    });
  },
  methods: {
    moment,
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    onTabChange(key, type) {
      // console.log(key, type);
      this[type] = key;
    },
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          modal.confirm({
            title: "该页面信息已编辑，是否需要保存?",
            onOk() {
              that.kitchenModify(values);
              return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log("Oops errors!"));
            },
            onCancel() {}
          });
        }
      });
    },
    kitchenModify(values) {
      axios({
        url:   this.$baseUrl + "kitchen/modify",
        method: "POST",
        withCredentials: true,
        data: {
          id: this.stallsEditId,
          ...values
        }
      }).then(() => {
        // todo: 由于修改档口可能导致stallsDetailtype发生变化，所以这里路由回退是不合理的。
        this.$router.go(-1);
      });
    },
    cancel() {
      let that = this;
      modal.confirm({
        title: "确定要放弃修改档口吗?",
        content: "该操作无法撤回，你还要继续吗？",
        onOk() {          
          that.$router.go(-1);
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {}
      });
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
      this.visible = false;
      this.staffEdit = false;
    },
    handleChange({ fileList }) {
      fileList.forEach((item, index) => {
        if (item.status == "error") {
          fileList.splice(index, 1);
          this.$message.error("上传失败！");
          return;
        }
      });
      this.fileList = fileList;
    },
    showMessage(val) {
      return val ? val : undefined;
    },
    changeBrandStatus(val) {
      switch (val) {
        case "吉刻联盟":
          return 1;
        case "闪电厨房":
          return 2;
        case "食秀坊":
          return 3;
        case "橘猫":
          return 4;
        case "海星微厨":
          return 5;
        case "美食门":
          return 6;
        default:
          return undefined;
      }
    }
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    // 是否有底价查看权限
    hasMinPriceRead() {
      return hasPermissions(
        [FacilityActions.MIN_PRICE_READ],
        this.authenticatedUser.actions
      );
    },
    // 是否有底价修改权限
    hasMinPriceEdit() {
      return hasPermissionsFun(
        [FacilityActions.MIN_PRICE_EDIT],
        this.authenticatedUser.actions
      );
    },
    // 是否有标价查看权限
    hasStandardPriceRead() {
      return hasPermissions(
        [FacilityActions.STANDARD_PRICE_READ],
        this.authenticatedUser.actions
      );
    },
    // 是否有标价修改权限
    hasStandardPriceEdit() {
      return hasPermissionsFun(
        [FacilityActions.STANDARD_PRICE_EDIT],
        this.authenticatedUser.actions
      );
    },
    // 是否有状态修改权限
    hasKitchenStatusEdit() {
      return hasPermissionsFun(
          [FacilityActions.KITCHEN_STATUS_EDIT],
          this.authenticatedUser.actions
      );
    },
  }
};


</script>

<style scoped>
.steps-action {
  float: right;
  /*margin-top: 24px;*/
}
</style>
